/*
Resources:
https://getbootstrap.com/docs/5.0/forms/overview/
https://www.tjvantoll.com/2013/04/15/list-of-pseudo-elements-to-style-form-controls/
*/

/****************/
/* custom list  */
/****************/

/* li::marker { content: "• "; } /* ::marker is bad, its shown even if list-style:none*/

ul {
	list-style-type: '•  ';
	/* ·•●⏺‣▶⏵✔⊚○◌🞅🞇🞉🖝 */
	padding-inline-start: 1em;
}

ul>li::marker {
	font-size: 1em;
}

/****************/
/* custom select */
/****************/

select:not([size], [multiple]) {
	-webkit-appearance: none;
	appearance: none;
	/* *
	background-image:url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2021.3%2012.1%22%20fill%3D%22%23333333%22%3E%3Cpath%20d%3D%22M10.7%2012.1L0%201.5%201.4.1l9.3%209.2L19.9%200l1.4%201.4z%22%2F%3E%3C%2Fsvg%3E");
	background-repeat:no-repeat;
	background-size:.8em .8em;
	background-position:right .3em top 50%;
	padding-right:1.5em;
	/* */
	/* */
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='4'><path d='M4 0h6L7 4'/></svg>");
	background-repeat: no-repeat;
	background-position: 100% 50%;
	background-size: 1em;
	padding-right: 1em;
	/*
	only possible if in :where() so background-color can be overwritten
	xbackground: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='4'%3E%3Cpath d='M4 0h6L7 4'/%3E%3C/svg%3E") no-repeat right center / 1em;
	xtext-overflow: ellipsis;
	*/
}

/***************************/
/* custom check-/radiobox  */
/***************************/

[type=radio], [type=checkbox] {
	-webkit-appearance: none;
	appearance: none;
	transform:rotate(.002deg); /* improve rendering in ff, chrome */
}

[type=radio], [type=checkbox] {
	width: .9em;
	height: .9em;
	background-color: #fff;
	border: solid 1px;
	position: relative;
	border-radius: .15em;
	padding: 0;
}

[type=radio]:checked, [type=checkbox]:checked {
	border-color: var(--color, blue);
}

[type=radio]::before, [type=checkbox]::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: normal;
	font-size: .65em;
}

[type=radio]:checked::before, [type=checkbox]:checked::before {
	background-color: var(--color, blue);
}

/* checkbox */

[type=checkbox]:checked::before {
	content: "✔";
}

/* radio */

[type=radio] {
	border-radius: 100%;
}

[type=radio]::before {
	border-radius: inherit;
	border: .15em solid #fff;
}

/* disabled */

[type=radio][disabled], [type=checkbox][disabled] {
	opacity: .5;
}

/* indeterminate */

[type=radio]:indeterminate::before, [type=checkbox]:indeterminate::before {
	color: currentColor;
	font-size: .8em;
	content: "─";
}

/*
	indicator (beta)
	z-index can not be behinde checkbox, only behinde ::before
*/

:is([type=radio], [type=checkbox]):not([disabled])::after {
	content: '';
	position: absolute;
	top: -60%;
	left: -60%;
	right: -60%;
	bottom: -60%;
	border-radius: 50%;
	transition: background-color .3s;
}

:is([type=radio], [type=checkbox]):hover::after {
	background-color: #0001;
}

/***************************/
/* color input             */
/***************************/

[type=color] {
	padding: 0;
	border-radius: 100%;
	width: 1.4em;
	height: 1.4em;
	border-width: 1px;
	overflow: hidden;
	box-shadow: 0 0 .5em #0003;
	cursor: pointer;
}

[type=color]::-moz-color-swatch {
	border: none;
}

[type=color]::-webkit-color-swatch {
	border: none;
}

[type=color]::-webkit-color-swatch-wrapper {
	padding: 0;
}

/***************************/
/* scrollbars              */
/***************************/

html {
	--scrollbar-thin:;
	/* space (: ;) => true, nospace (:;) = false  */
	--scrollbar-color-track: var(--color-area, #f4f4f4);
	--scrollbar-color-thumb: var(--color, #ccc);
}

* {
	scrollbar-width: var(--scrollbar-thin) thin;
	scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
}

::-webkit-scrollbar {
	width: var(--scrollbar-thin) 8px;
	height: var(--scrollbar-thin) 8px;
}

::-webkit-scrollbar-track {
	background: var(--scrollbar-color-track);
}

::-webkit-scrollbar-thumb {
	background-color: var(--scrollbar-color-thumb);
}

select, textarea { --scrollbar-thin: ; }

/***************************/
/* range inputs            */
/***************************/

[type=range] {
	background-color: transparent;
	border: none;
	-webkit-appearance: none;
	padding: 0;
}

/* todo where(webkit, moz) (forgiving) */

[type=range]::-webkit-slider-runnable-track {
	box-sizing: border-box;
	height: .5em;
	background: #fff;
	border: 1px solid #0008;
	border-radius: 1em;
	cursor: pointer;
}

[type=range]::-moz-range-track {
	box-sizing: border-box;
	height: .5em;
	background: #fff;
	border: 1px solid #0008;
	border-radius: 1em;
	cursor: pointer;
}

[type=range]::-webkit-slider-thumb {
	box-sizing: border-box;
	width: 1em;
	height: 1em;
	background: var(--color);
	border-radius: 100%;
	cursor: pointer;
	margin-top: -.3em;
	-webkit-appearance: none;
}

[type=range]::-moz-range-thumb {
	box-sizing: border-box;
	width: 1em;
	height: 1em;
	background: var(--color);
	border-radius: 100%;
	cursor: pointer;
}

[type=range]:hover::-webkit-slider-thumb {
	box-shadow: 0 0 0 .5em #0001
}

[type=range]:hover::-moz-range-thumb {
	box-shadow: 0 0 0 .5em #0001
}

/* autofill (chrome) */
:-webkit-autofill {
	/* also works for firefox! */
	filter: none;
	box-shadow: 0 0 0 100px var(--color-light) inset;
}

::selection {
	background: hsl(var(--hsl), 0.3);
}

/* Spin Buttons
- bigger chromes spinners
- hide firefox spnners
*
input[type=number] {
    appearance:textfield;
}
@supports selector(::-webkit-inner-spin-button) {
	input[type="number"] {
		padding-right:1.2em;
	}
	::-webkit-inner-spin-button {
		position: absolute;
		right: 0;
		top: -1px;
		bottom: -1px;
		padding-left:.2em;
		padding-right:.2em;
	}
}
/**/


/***************************/
/* details / summary       */
/***************************/

details { border-bottom: 1px solid var(--color-line); }
summary {
	padding:calc(var(--gap) / 2) 0;
	line-height:1; /* to center floated ::after */
	--line-height:1; /* to center floated ::after */
}
details[open] > summary { margin-bottom: calc(var(--gap) / 2 ); }
details[open] { padding-bottom: calc(var(--gap) / 1); }

/* nested *
details details {
	margin-inline:calc(var(--gap)/2);
}
/* */

/*
# custom icon
- display:flex not working in safari 14
needed?:
summary::marker { display: none; }
summary::-moz-list-bullet { xlist-style-type: none; }
*/
summary { list-style-type: none; }
summary::-webkit-details-marker { display: none; }
summary::after {
	line-height:.9;
	content:'▾'; /* not working in all browsers: ⏵❯ unknown support: ⟩⟩ */
	transform: rotate(-90deg);
	width: 1rem;
	height: 1rem;
	float: right;
	transition: transform .2s;
	/* customize
	font-family:--u1-ico-font;
	background-image: var(--ico-chevron);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 1rem auto;
	*/
}
details[open] > summary::after {
	transform: rotate(0deg);
}
/**/